
























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class BigScreenVideoAdressVue extends Vue {
    @Prop() VideoGroupCodeData: any;
    @Prop() isYsvideo: any;
    @Prop() VideolaunchData: any;
    @Prop() ysVideoGroupTreeData: any;
    private defaultProps: any = {
        children: "children",
        label: "name",
    };

    public ClickObj: any = {};
    private handleNodeClick(data: any, node: Node, vc: any) {
        console.log(data);
        
        //通知父组件的父组件 告诉他我点击了什么  在父组件的父组件中请求对应的数据
        let dataObj: any = {
            groupCode: data.id,
            animalTypeId: "",
            farmId: data.farmId ? data.farmId : "",
            deviceCode: data.deviceCode ? data.deviceCode : "",
            houseId: data.houseId ? data.houseId : "",
        };
        this.ClickObj = dataObj;
        console.log(this.ClickObj);
        
        this.$emit("SetVideoSendData", dataObj);
    }
    private handleYSNodeClick(data: any, node: Node, vc: any) {
        console.log(data);
        
        //通知父组件的父组件 告诉他我点击了什么  在父组件的父组件中请求对应的数据
        let dataObj: any = {
            groupCode: data.id,
            animalTypeId: "",
            farmId: data.farmId ? data.farmId : "",
            deviceCode: data.deviceCode ? data.deviceCode : "",
            houseId: data.houseId ? data.houseId : "",
        };
        this.ClickObj = dataObj;
        console.log(this.ClickObj);
        
        this.$emit("SetYSVideoSendData", dataObj);
    }
}
